import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorProps } from '../../form-builder/FormBuilderTypes';
import Checkbox from '../../shared/form-control/Checkbox';

const ShowInResourceView: FC<EditorProps> = (props) => {
  const { action, patchAction } = props;
  const { shownInResourceOverview } = action;
  const { t } = useTranslation('form-builder');

  const onChange = useCallback(
    (newValue: boolean) => {
      patchAction({ shownInResourceOverview: newValue });
    },
    [patchAction],
  );

  return (
    <Checkbox
      className="mt-4"
      label={t('action-properties.common.show-in-resource-view')}
      value={shownInResourceOverview ?? false}
      onChange={onChange}
    />
  );
};

export default ShowInResourceView;
